import { memo } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';

import styles from './FacultyExcellenceProgram.module.scss';

const VectorImage = dynamic(() => import('./images/vector.svg'));
const FEPIconImage = dynamic(() => import('./images/fep-icon.svg'));

 function FacultyExcellenceProgram() {
  const trackMoengageEvent = useMoengageEventTracker();

  return (
    <section id="faculty-excellence-program" className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentTextWrapper}>
          <FEPIconImage />
          <p>Kickstart your teaching career with practical training, expert guidance and the skills to inspire.</p>
        </div>
        <Link
          href="https://www.studyiq.com/lp/teacher-excellence-program.html"
          target="_blank"
          onClick={() => trackMoengageEvent('fep_clicked_banner')}
          data-testid="fep-explore-now"
        >
          <span>Explore Now</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>

      <div className={styles.imageWrapper}>
        <VectorImage />
      </div>
    </section>
  );
}
export default memo(FacultyExcellenceProgram);
